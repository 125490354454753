import { css } from 'styled-components'
import { minMq, maxMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const topImg = css`
  padding-bottom: 10px;
  width: 100%;

  ${minMq('MD')} {
    display: block;
    margin: 0 auto;
    max-width: 860px;
    padding-bottom: 25px;
  }
`

const targetBlock = css`
  margin-top: 30px;
  
  ${minMq('MD')} {
    margin-top: 35px;
  }
`

const list = css`
  > li {
    font-size: 1.5rem;
    font-weight: 700;

    ${minMq('MD')} {
      font-size: 1.8rem;
    }

    &::before {
      content: "・";
    }

    + li {
      margin-top: 7px;
      
      ${minMq('MD')} {
        margin-top: 14px;
      }
    }
  }
`

export const style = {
  topImg,
  targetBlock,
  list
}