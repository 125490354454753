import React from 'react'
import { CSSProp } from 'styled-components'
import getConfig from 'next/config'

import { style } from './Measures.style'
import { cmnStyle } from './homeCommon.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Measures = () => {
  return (
    <section id="measures_section">
      <Content>
        <SectionTitle sectionTitle="マイナンバーカードの安全対策" />
        <img
          src={`${basePath}/home/img/measures01.png`}
          alt="セキュリティー対策は大丈夫？"
          css={style.topImg}
        />
        <div css={style.txtBlock}>
          <p>●カードのICチップには、税や年金など<em>プライバシー性の高い情報は記録されません</em></p>
          <small>※不正にICチップを読み出そうとすると、自動で壊れるように設計されています</small>
        </div>
        <div css={style.txtBlock}>
          <p>●マイナンバーを利用する手続きでは厳格な本人確認が行われるため、番号を見られても<em>悪用されたり個人情報が漏れたりすることはありません</em></p>
        </div>
        <div css={style.infoBlock}>
          <p css={cmnStyle.blockTtl}>万が一紛失した場合は？</p>
          <p css={style.infoTxt}>マイナンバー総合フリーダイヤルに連絡すれば、いつでも一時利用停止手続きができます</p>
          <div css={style.telBlock}>
            <img src={`${basePath}/common/tel.png`} />
            <p>0120-95-0178</p>
            <small>※一時利用停止手続のみ、24時間・年中無休</small>
          </div>
          <a
            href="https://www.city.niigata.lg.jp/kurashi/todokede/mynumber_card/mynumber_card.files/safety_mynocard.pdf"
            target="_blank"
            rel="noopener noreferrer"
            css={cmnStyle.btn}
          >
            <span>詳しくはこちら</span>
          </a>
          <p css={cmnStyle.btnDescription}>※PDFが開きます。<br />持ち歩いても大丈夫！マイナンバーカードの安全性（PDF：1,428KB）</p>
        </div>
      </Content>
    </section>
  )
}

export default Measures
