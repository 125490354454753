import React from 'react'
import { CSSProp } from 'styled-components'
//import { style } from './Content.style'
import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'
declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const content = css`
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(${variables.UNIT} * 2);
  padding-right: calc(${variables.UNIT} * 2);
  width: 100%;

  ${minMq('MD')} {
    max-width: ${variables.CONTENT_W};
  }
`

interface OwnProps {
  children: React.ReactNode
}

type Props = OwnProps

const Content: React.VFC<Props> = (props) => {
  return <div css={content}>{props.children}</div>
}

export default Content
