import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const exampleTxt = css`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 27px;
  
  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`


const infoBlock = css`
  font-weight: 700;
  margin-top: 30px;

  ${minMq('MD')} {
    margin-top: 60px;
  }
`

const infoTxt = css`
  display: inline-block;
  font-size: 1.6rem;
  margin-bottom: 15px;
  margin-top: 20px;

  ${minMq('MD')} {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
`

const telBlock = css`
  display: inline-block;
  margin-bottom: 30px;
  
  ${minMq('MD')} {
    margin: 30px;
  }

  > * {
    display: inline-block;
  }

  img {
    width: 30px;
    vertical-align: text-bottom;
    
    ${minMq('MD')} {
      width: 50px;
    }
  }

  p {
    font-size: 2.2rem;
    margin-left: 7px;
    
    ${minMq('MD')} {
      font-size: 3rem;
      margin-left: 15px;
    }
  }

  small {
    font-size: 1.6rem;
    margin-left: 10px;
    
    ${minMq('MD')} {
      font-size: 1.8rem;
      margin-left: 20px;
    }
  }
`

export const style = {
  exampleTxt,
  infoBlock,
  infoTxt,
  telBlock
}
