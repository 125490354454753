import React from 'react'
// import axios from 'axios'
import { CSSProp } from 'styled-components'
import getConfig from 'next/config'

import { style } from './NewsItems.style'
import variables from '../shared/styles/variables'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

interface titleProps {
  rendered: string
}

interface afcProps {
  address: string
  capacity: number
  event_date: string
  reception_time: string
  google_map: string
  memo: string
  pdf: string
  start: string
  ward: string
}

interface OwnProps {
  id: number
  title: titleProps
  acf: afcProps
  link: string
  //changeNews: (news: []) => void
}

type Props = OwnProps

const NewsItem: React.VFC<Props> = (props) => {
  const { title, acf } = props

  let areaCStyle = null;
  switch (acf.ward) {
    case "中央区":
      areaCStyle = style.funcAreaC(variables.AREA_C_CHUO)
      break;

    case "東区":
      areaCStyle = style.funcAreaC(variables.AREA_C_HIGASHI)
      break;

    case "西区":
      areaCStyle = style.funcAreaC(variables.AREA_C_NISHI)
      break;

    case "南区":
      areaCStyle = style.funcAreaC(variables.AREA_C_MINAMI)
      break;

    case "北区":
      areaCStyle = style.funcAreaC(variables.AREA_C_KITA)
      break;

    case "江南区":
      areaCStyle = style.funcAreaC(variables.AREA_C_KONAN)
      break;

    case "西蒲区":
      areaCStyle = style.funcAreaC(variables.AREA_C_NISHIKAN)
      break;

    case "秋葉区":
      areaCStyle = style.funcAreaC(variables.AREA_C_AKIHA)
      break;

    case "各区":
      areaCStyle = style.funcAreaC(variables.AREA_C_KAKUKU)
      break;
  }

  return (
    <article css={style.card}>
      <p css={[style.area, areaCStyle]}>{acf.ward}</p>
      <div css={style.wrapMainTxt}>
        <div css={style.titleBlock}>
          <p css={style.title}>{title.rendered}</p>
          <p css={style.capacity}>定員：{acf.capacity ? acf.capacity : "なし"}</p>
          {
            acf.pdf ?
              <a
                href={acf.pdf}
                target="_blank"
                rel="noopener noreferrer"
                css={style.pdfTooltip}
              >
                <img src={`${basePath}/common/pdf.svg`} alt="pdf" />
              </a>
              : ""
          }
        </div>
        <dl css={style.dList}>
          <div css={style.dLItem}>
            <dt>開催日</dt>
            <dd css={style.eventDate}>{acf.event_date}</dd>
          </div>
          {
            acf.reception_time ?
              <div css={style.dLItem}>
                <dt>受付時間</dt>
                <dd css={style.eventDate}>
                  {acf.reception_time}
                </dd>
              </div>
              : ""
          }
          {
            acf.address ?
              <div css={style.dLItem}>
                <dt>住所</dt>
                <dd css={style.address}>
                  {acf.address}
                  {acf.google_map ?
                    <a
                      href={acf.google_map}
                      target="_blank"
                      rel="noopener noreferrer"
                      css={style.mapBtn}
                    >
                      <span>地図を表示</span>
                    </a>
                    : ""
                  }
                </dd>
              </div>
              : ""
          }
        </dl>
        {
          acf.memo ?
            <p css={style.memo}>{acf.memo}</p>
            : ""
        }
      </div>
    </article>
  )
}

export default NewsItem
