import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
// import variables from '../../shared/styles/variables'

const txt = css`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 28px;

  ${minMq('MD')} {
    font-size: 1.8rem;
    line-height: 33px;
  }
`

export const style = {
  txt
}