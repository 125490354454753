import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const scrollTopBtn = css`
  bottom: 16px;
  color: ${variables.WHITE};
  height: 50px;
  position: fixed;
  right: 16px;
  transition: all .6s;
  width: 200px;


  ${minMq('MD')} {
		bottom: 35px;
		right: 45px;
  }
  
  &.is-hover > span {
    opacity: .9;
  }

  > span {
    align-items: center;
    background-color: ${variables.THEME_C};
    border-radius: 9999px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
    display: flex;
    height: 100%;
    justify-content: center;
    opacity: .8;
    transition: all .6s;
  }
`

const activeStyle = css`
  opacity: 1;
`
const hiddenStyle = css`
  opacity: 0;
  pointer-events: none;
`

export const style = {
  scrollTopBtn,
  activeStyle,
  hiddenStyle
}