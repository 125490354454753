import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
// import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const siteFooter = css`
  background-color: ${variables.LIGHT_BLUE};
  margin-top: 60px;
  padding-bottom: 11px;
  padding-top: 15px;
  width: 100%;

  ${minMq('MD')} {
    margin-top: 100px;
  }
`

const copyright = css`
  text-align: center;
`


export const style = {
  siteFooter,
  copyright
}