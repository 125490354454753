import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'


const siteHeader = css`
  background-color: ${variables.WHITE};
  height: ${variables.HEADER_H_SP};
  left: 0;
  padding: calc( ${variables.UNIT} * 2 );
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  
  ${minMq('MD')} {
    height: ${variables.HEADER_H_PC};
    justify-content: space-between;
  }
`

const wrapContent = css`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
`

const siteTitle = css`
  align-items: center;
  color: ${variables.THEME_C};
  display: flex;
  flex: 1;
  font-size: 1.4rem;
  font-weight: 700;
  position: static;
  z-index: 1;

  ${minMq('MD')} {
    font-size:min(2.5vw, 3rem);
  }

  
  h1 > span {
    display: inline-block;

    ${minMq('MD')} {
      display: inline-block;
    }
  }
`

export const style = {
  siteHeader,
  wrapContent,
  siteTitle
}