import { css } from 'styled-components'
import variables from '../../shared/styles/variables'
import { minMq, maxMq } from '../../shared/styles/breakPoints'

const content = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 1220px;
  padding: 0 10px 101px;

  ${maxMq('MD')} {
    padding-bottom: 60px;
  }
`

const pageHeader = css`
  padding-bottom: 30px;
  
  ${minMq('MD')} {
    padding-bottom: 30px;
  }
`

const nav = css`
  margin-bottom: 100px;
`

const list = css`
  column-gap: 12px;
  display: grid;
  grid-auto-columns: 230px;
  grid-auto-rows: 65px;
  grid-template-columns: repeat(auto-fit, 230px);
  justify-content: center;
  row-gap: 20px;

  ${minMq('MD')} {
    grid-auto-rows: 80px;
  }
`

const btn = css`
  align-items: center;
  background-color: ${variables.THEME_C};
  border-radius: 9999px;
  color: ${variables.WHITE};
  display: flex;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  text-align: center;

  > * {
    flex: 1;
  }
`

const imgBlock = css`
  display: grid;
  row-gap: 40px;

  ${minMq('MD')} {
    row-gap: 50px;
  }

  > :last-child {
    padding-top: 20px;

    ${minMq('MD')} {
      padding-top: 30px;
    }
  }
`

const eventSiteLink = css`
  margin: 0 auto;
  width: min(100%, 1000px);
`

const reservation = css`
  margin-top: 40px;

  ${minMq('MD')} {
    margin-top: 45px;
  }
`

const topText = css`
  display: inline-block;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 18px;

  ${minMq('MD')} {
    font-size: 2.6rem;
  }
`

const reservationAttention = css`
  color: ${variables.SUB_TXC};
  font-size: 1.6rem;
  font-weight: 700;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

const reservationMethod = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 24px 0 16px;

  ${minMq('LG')} {
    grid-template-columns: repeat(2, 1fr);
  }

  > * {
    border: 1px solid ${variables.BASE_BDC};
    padding: 32px 20px;

    ${minMq('LG')} {
      &:nth-child(even) {
        border-left: none;
      }
    }

    ${maxMq('LG')} {
      &:nth-child(even) {
        border-top: none;
      }
    }
  }
`

const reservationMethodText = css`
  align-items: center;
  margin-bottom: 16px;
  
  ${minMq('MD')} {
    display: grid;
    column-gap: 18px;
    grid-template-columns: auto;
    grid-template-columns: auto auto;
    margin-bottom: 20px;
    place-content: center;
  }
  
  ${maxMq('MD')} {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`

const mainText = css`
  font-size: 2rem;
  font-weight: 700;
  
  ${minMq('MD')} {
    font-size: 2.6rem;
  }
`

const badge = css`
  background-color: ${variables.ROSE};
  border-radius: 8px;
  color: ${variables.WHITE};
  padding: 6px 10px;
`

const reservationBtn = css`
  ${minMq('MD')} {
    margin-top: 0;
  }
`

const tel = css`
  color: ${variables.SUB_TXC};
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
  text-align: center;

  ${minMq('MD')} {
    font-size: 4.2rem;
  }
`

const note = css`
  background-color: ${variables.LIGHT_YELLOW};
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  padding: 15px 0;
`
const infoLink = css`
margin: 0 auto;
width: min(100%, 1000px);
> picture {
  
  > img {
    margin-bottom: 30px;
    
    ${minMq('MD')} {
      margin-bottom: 60px;
    }
  }
}
`

export const style = {
  content,
  pageHeader,
  nav,
  list,
  btn,
  imgBlock,
  eventSiteLink,
  reservation,
  topText,
  reservationAttention,
  reservationMethod,
  reservationMethodText,
  mainText,
  badge,
  reservationBtn,
  tel,
  note,
  infoLink
}
