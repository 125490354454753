import React from 'react'
import { CSSProp } from 'styled-components'

import { style } from './Contact.style'
import { cmnStyle } from './homeCommon.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Contact = () => {
  return (
    <section id="contact_section">
      <Content>
        <SectionTitle sectionTitle="このサイトに関する問い合わせ" />
        <div css={style.block}>
          <p css={cmnStyle.blockTtl}>問い合わせ先</p>
          <ul css={style.list}>
            <li css={style.blockTxt}>出張申請受付サービス（巡回出張申請を含む）について</li>
            <li css={style.blockTxt}>コンビニ交付サービスについて（課税証明に関するものを除く）</li>
          </ul>
          <p css={style.blockTxt}>新潟市 市民生活部市民生活課</p>
          <p css={style.tel}><strong>TEL：<br data-mq="sp" />025-226-1013</strong></p>
          <p><strong>E-mail：<br data-mq="sp" />shiminseikatsu@city.niigata.lg.jp</strong></p>
        </div>
        <div css={style.block}>
          <p css={cmnStyle.blockTtl}>問い合わせ先</p>
          <ul css={style.list}>
            <li css={style.blockTxt}>マイナンバー制度全般について</li>
          </ul>
          <p css={style.blockTxt}>マイナンバー総合フリーダイヤル</p>
          <p css={style.tel}><strong>TEL：<br data-mq="sp" />0120-95-0178</strong></p>
          <p css={style.blockTxt}><span css={style.inTheTxt}>平日（年末年始除く）</span><span css={style.inTheTxt}>9:30～20:00、土日祝9:30～17:30</span></p>
        </div>
        <div css={style.block}>
          <p css={cmnStyle.blockTtl}>マイナンバーカード申請や制度について</p>
          <p css={style.blockTxt}>マイナンバーカード申請や制度について詳しくは新潟市&emsp;マイナンバーカード&emsp;ホームページをご覧ください。</p>
        </div>
        <a
          href="https://www.city.niigata.lg.jp/kurashi/todokede/mynumber_card/mynumber_card.html"
          target="_blank"
          rel="noopener noreferrer"
          css={[cmnStyle.btn, style.btn]}
        >
          <span>詳しくはこちら</span>
        </a>
        <p css={cmnStyle.btnDescription}>※外部リンクが開きます。<br />マイナンバーカード（新潟市HP）</p>
      </Content>
    </section>
  )
}

export default Contact
