import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const block = css`
  margin-bottom: 30px;

  ${minMq('MD')} {
    margin-bottom: 50px;
  }

  strong {
    font-size: 3rem;
    font-weight: 700;
    line-height: 45px;

    ${maxMq('MD')} {
      font-size: 1.8rem;
    }
  }
`

const blockTtl = css`
  background-color: ${variables.LIGHT_BLUE};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 25px;
  padding: 8px 20px 7px;

  ${minMq('MD')} {
    font-size: 2rem;
    margin-bottom: 26px;
    padding: 11px 26px 10px;
  }
`

const blockTxt = css`
  font-size: 1.6rem;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

const list = css`
  margin-bottom: 15px;

  ${minMq('MD')} {
    margin-bottom: 30px;
  }

  > li {
    text-indent: 1;

    &::before {
      content: "・";
    }


    + li {
      margin-top: 5px;
    }
  }
`

const tel = css`
  margin-bottom: 2px;
  margin-top: 10px;

  ${minMq('MD')} {
    margin-bottom: 10px;
    margin-top: 20px;
  }
`

const inTheTxt = css`
  ${maxMq('MD')} {
    display: inline-block;
  }
`

const btn = css`
  margin-top: 30px;

  ${minMq('MD')} {
    margin-top: 60px;
  }
`

export const style = {
  block,
  blockTtl,
  blockTxt,
  list,
  tel,
  inTheTxt,
  btn
}