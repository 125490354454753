import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const em01 = css`
  background: linear-gradient(transparent 70%, ${variables.YELLOW} 0%);
  line-height: 30px;

  ${minMq('MD')} {
    background: linear-gradient(transparent 60%, ${variables.YELLOW} 0%);
    line-height: 38px;
  }
`

const btn = css`
  background-color: ${variables.SUB_C};
  border-radius: 9999px;
  color: ${variables.WHITE};
  display: block;
  font-size: 1.6rem;
  margin: 20px auto 0;
  max-width: 350px;
  padding: 14px 20px 15px;
  position: relative;
  text-align: center;
  width: 100%;

  ${minMq('MD')} {
    font-size: 2rem;
    line-height: 29px;
    margin-top: 40px;
  }
`

const btnDescription = css`
  font-weight: 500;
  line-height: 22px;
  margin-top: 10px;
  text-align: center;

  ${minMq('MD')} {
    line-height: 28px;
    margin-top: 16px;
  }
`

const table = css`
  width: 100%;

  th,
  td {
    vertical-align: middle;
  }

  th {
    background-color: ${variables.LIGHT_BLUE};
    text-align: center;

    + th {
      border-left: 1px solid ${variables.BASE_BDC}
    }
  }

  td {
    border: 1px solid ${variables.BASE_BDC};
  }
`

const blockTtl = css`
  background-color: ${variables.LIGHT_BLUE};
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 20px;
  padding: 8px 20px 7px;

  ${minMq('MD')} {
    font-size: 2rem;
    margin-bottom: 40px;
    padding: 11px 26px 10px;
  }
`

export const cmnStyle = {
  em01,
  btn,
  btnDescription,
  table,
  blockTtl
}