import React from 'react'
import { useToggle } from 'react-use'
//import useBool from '../../hooks/useBool'
import { CSSProp } from 'styled-components'
import { style } from './Navigation.style'
import { Link as Scroll } from 'react-scroll'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Navigation: React.VFC = () => {
  const [on, toggle] = useToggle(false)

  return (
    <div css={style.wrapMenu}>
      <p css={style.navSide}>メニュー</p>
      <button css={on ? [style.open, style.menu] : style.menu} onClick={toggle}>
        <span css={style.bar}></span>
        <span css={style.bar}></span>
        <span css={style.bar}></span>
      </button>

      <nav css={on ? [style.nav, style.smallContent] : style.nav} id="nav">
        <ul css={on ? [style.navList, style.openNavList] : style.navList}>
          <li css={style.navItem}>
            <Scroll
              to="guidance_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>出張申請サービスのご案内</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="necessary_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>出張申請会場に持参するもの</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="news_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>出張申請会場・日程のお知らせ</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="whatis_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>マイナンバーカードとは</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="merit_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>マイナンバーカード取得のメリット</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="certificate_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>スマホ用電子証明書搭載サービス</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="measures_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>マイナンバーカードの安全対策</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="apply_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>選べる申請方法</span>
            </Scroll>
          </li>
          <li css={style.navItem}>
            <Scroll
              to="contact_section"
              smooth={true}
              duration={600}
              offset={-100}
              onClick={toggle}
            >
              <span css={style.navLink}>このサイトに関する問い合わせ</span>
            </Scroll>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navigation
