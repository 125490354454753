import React from 'react'
import { CSSProp } from 'styled-components'
import { style } from './SiteFooter.style'
import Content from '../Content/Content'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const SiteFooter: React.VFC = () => {
  return (
    <footer css={style.siteFooter}>
      <Content>
        <p css={style.copyright}>© Niigata City.</p>
      </Content>
    </footer>
  )
}

export default SiteFooter
