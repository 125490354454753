import { css } from 'styled-components'
import { minMq, maxMq } from '../../shared/styles/breakPoints'
// import variables from '../../shared/styles/variables'
import variables from '../../shared/styles/variables'

const topImg = css`
  display: block;
  margin: 0 auto 47px;
  max-width: 620px;
  width: 100%;
`

const subTitle = css`
  font-size: 2.6rem;
  font-weight: 700;
  margin-bottom: 60px;
  text-align: center;

  ${maxMq('MD')} {
    font-size: 1.8rem;
    line-height: 30px;
    margin-bottom: 30px;
  }
`

const card = css`
  background-color: ${variables.LIGHT_YELLOW};
  border-radius: 20px;
  display: flex;
  margin-top: 20px;
  padding: 15px 10px;

  ${minMq('MD')} {
    margin-top: 30px;
    padding: 20px 70px 25px;
  }

  ${maxMq('MD')} {
    flex-direction: column;
  }
`

const emphasized = css`
  color: ${variables.SUB_C};
`

const txtBlock = css`
  flex: 1;

  header {
    align-items: center;
    background-color: ${variables.SUB_C};
    border-radius: 9999px;
    color: ${variables.WHITE};
    display: flex;
    font-size: 1.6rem;
    font-weight: 700;
    height: 46px;
    justify-content: center;
    width: 150px;
    margin-bottom: 20px;

    ${minMq('MD')} {
      font-size: 2rem;
      height: 50px;
      width: 190px;
      margin-left: -40px;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 700;

    ${minMq('MD')} {
      font-size: 2rem;
      line-height: 29px;
    }

    + * {
      margin-top: 10px;
    }
  }

  small {
    display: block;
    line-height: 24px;
  }
`

const wrapBtns = css`
  display: flex;
  gap: 15px;
  justify-content: center;
  padding-bottom: 20px;

  ${maxMq('MD')} {
    flex-direction: column;
    padding-bottom: 15px;
    gap: 5px;
  }

  > * {
    flex-basis: 45%;
  }
`

const wrapBtn = css`
  padding-bottom: 15px;

  ${minMq('MD')} {
    padding-bottom: 20px;
  }
`

const imgBlock = css`
  > img {
    padding-top: 5px;
    width: 200px;

    ${maxMq('MD')} {
      display: block;
      margin: 10px auto 0;
    }
  }
`

export const style = {
  topImg,
  subTitle,
  card,
  emphasized,
  txtBlock,
  wrapBtns,
  wrapBtn,
  imgBlock,
}