import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { CSSProp } from 'styled-components'
import axios from 'axios'

import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'
import Items from '../NewsItems'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const News = () => {
  const router = useRouter()
  const query = router.query
  const [news, setNews] = useState([])

  // 一覧表示
  const setContent = (item: any) => {
    return <Items key={item.id} {...item} />
  }

  const getNews = async () => {
    const newEndPoint = `${process.env.NEXT_PUBLIC_API_BASE_URL}${process.env.NEXT_PUBLIC_NEWS}`

    await axios
      .get(newEndPoint, {
        params: {
          per_page: 100,
          page: 1,
        },
      })
      .then((res) => {
        setNews(res.data)
      })
  }

  useEffect(() => {
    if (!router.isReady) return
    getNews()
  }, [query, router])

  return (
    <section id="news_section">
      <Content>
        <SectionTitle sectionTitle="出張申請会場・日程のお知らせ" />
        {(() => {
          return news.map((contentObj) => setContent(contentObj))
        })()}
      </Content>
    </section>
  )
}
export default News
