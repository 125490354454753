import React from 'react'
import { CSSProp } from 'styled-components'
import { style } from './PageHeader.style'
import { cmnStyle } from '../Home/homeCommon.style'
import getConfig from 'next/config'
import { Link as Scroll } from 'react-scroll'
import Content from '../Content/Content'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath
declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

interface OwnProps {
  pageTitle: string
}

type Props = OwnProps

const PageHeader: React.FC<Props> = () => {
  return (
    <div css={style.content}>
      <header css={style.pageHeader} id="page_header">
        <img
          src={`${basePath}/home/img/main.png`}
          alt="マイナンバーカード申請受付中！　手続きラクラク臨時申請窓口ご利用ください　 新潟市民向け郵送交付キャンペーン実施中 新潟市・佐渡市共通商品券1000円プレゼント"
        />
      </header>
      <nav css={style.nav}>
        <ul css={style.list}>
          <li>
            <Scroll
              to="guidance_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>出張申請サービスのご案内</span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="necessary_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>
                出張申請会場に
                <br />
                持参するもの
              </span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="news_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>
                出張申請会場・日程の
                <br />
                お知らせ
              </span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="whatis_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>マイナンバーカードとは</span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="merit_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>
                マイナンバーカード取得の
                <br />
                メリット
              </span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="certificate_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>
                スマホ用電子証明書
                <br />
                搭載サービス
              </span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="measures_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>
                マイナンバーカードの
                <br />
                安全対策
              </span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="apply_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>選べる申請方法</span>
            </Scroll>
          </li>
          <li>
            <Scroll
              to="contact_section"
              smooth={true}
              duration={600}
              offset={-100}
              css={style.btn}
            >
              <span>
                このサイトに関する
                <br />
                問い合わせ
              </span>
            </Scroll>
          </li>
        </ul>
      </nav>

      <div css={style.imgBlock}>
        <picture>
          <source
            srcSet={`${basePath}/home/img/sub01.png`}
            media="(min-width: 769px)"
            type="image/png"
          />
          <img
            src={`${basePath}/home/img/sub01Sp.png`}
            alt="便利な申請窓口についての画像"
          />
        </picture>
        <picture>
          <source
            srcSet={`${basePath}/home/img/sub04.png`}
            media="(min-width: 769px)"
            type="image/png"
          />
          <img
            src={`${basePath}/home/img/sub04Sp.png`}
            alt="申請書の活用についての画像"
          />
        </picture>
        <picture>
          <source
            srcSet={`${basePath}/home/img/sub05.png`}
            media="(min-width: 769px)"
            type="image/png"
          />
          <img
            src={`${basePath}/home/img/sub05Sp.png`}
            alt="カードの受取りについての画像"
          />
        </picture>
      </div>

      <Content>
        <div css={style.reservation}>
          <p css={cmnStyle.blockTtl}>来庁予約はこちら</p>
          <p css={style.topText}>
            <strong css={cmnStyle.em01}>
              簡単・便利なWeb予約がおすすめです！
            </strong>
          </p>
          <p css={style.reservationAttention}>
            ※電話予約は現在大変繋がりにくく、ご迷惑をお掛けしております
          </p>
          <div css={style.reservationMethod}>
            <div>
              <p css={style.reservationMethodText}>
                <span css={style.mainText}>Webでご予約</span>
                <span css={style.badge}>24時間受付中</span>
              </p>
              <a
                href="https://yoyaku53.mynum.jp/niigata-city/"
                target="_blank"
                rel="noopener noreferrer"
                css={[cmnStyle.btn, style.reservationBtn]}
              >
                <span>予約サイトはこちら</span>
              </a>
              <p css={cmnStyle.btnDescription}>※外部リンクが開きます。</p>
            </div>
            <div>
              <p css={style.reservationMethodText}>
                <span css={style.mainText}>お電話でご予約</span>
                <span css={style.badge}>受付時間：平日8:30～17:30</span>
              </p>
              <p css={style.tel}>☎025-245-0300</p>
              <p css={style.note}>
                <span>※お掛け間違いにご注意ください。</span>
              </p>
            </div>
          </div>
          <p css={style.reservationAttention}>
            ※交付通知書ID（ハガキ表面に記載）をご用意ください
          </p>
        </div>
      </Content>
    </div>
  )
}

export default PageHeader
