import React from 'react'
import { CSSProp } from 'styled-components'

import { style } from './Apply.style'
import { cmnStyle } from './homeCommon.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Apply = () => {
  return (
    <section id="apply_section">
      <Content>
        <SectionTitle sectionTitle="選べる申請方法" />
        <p css={style.topTxt}>
          マイナンバーカードは自宅に届いている「交付申請書（個人番号通知書）」で申請できます。
        </p>
        <p css={style.topAttention}>
          ※「交付申請書」の再発行を希望する場合は、区役所区民生活課（中央区は窓口サービス課）で再発行できます。
        </p>
        <div css={style.exampleBlock}>
          <p css={[cmnStyle.blockTtl, style.includesAttention]}>
            <span>スマートフォン</span>
          </p>
          <p css={style.exampleTxt}>
            「交付申請書（個人番号通知書）」に記載の二次元コードを読み取り、専用ウェブサイトから申請してください。顔写真はスマートフォンで撮影します。
          </p>
        </div>
        <div css={style.exampleBlock}>
          <p css={[cmnStyle.blockTtl, style.includesAttention]}>
            <span>郵便</span>
          </p>
          <p css={style.exampleTxt}>
            「交付申請書（個人番号通知書）」に必要事項を記載し顔写真を貼って、同封の返信用封筒に入れて投函してください。
          </p>
          <p css={style.exampleTxt}>
            封筒を紛失した場合は、以下の宛先へお送りください。
          </p>
          <p css={style.exampleTxt}>郵便番号&emsp;219-8732</p>
          <p css={style.exampleTxt}>
            日本郵便株式会社川崎東郵便局郵便私書箱第2号
          </p>
          <p css={style.exampleTxt}>
            地方公共団体情報システム機構&emsp;個人番号カード交付申請書受付センター&emsp;宛
          </p>
        </div>
        <div css={style.exampleBlock}>
          <p css={cmnStyle.blockTtl}>区の窓口［申請補助サービス］</p>
          <p css={style.exampleTxt}>
            お住まいの区の区役所区民生活課（中央区は窓口サービス課）で、職員が申請手続きをサポートします。顔写真は備え付けのタブレット端末で撮影できます。新規申請の方は手数料無料です。
          </p>
          <p css={style.exampleTxt}>
            本人確認書類２点（運転免許証等）をはじめ、お持ち頂く書類があります。詳しくは区役所までお尋ねください。
          </p>
          <p css={style.exampleNote}>
            <span>※紛失等による再発行の場合は有料（1,000円）</span>
            <span>※事前に電話予約が必要（北区、西区を除く）</span>
            <span>※新潟市に住民票がある方が対象</span>
            <span>
              ※特急発行の場合は以下を参照してください。（対象、要件、内容が異なります）
            </span>
            <a
              href="https://www.city.niigata.lg.jp/kurashi/todokede/mynumber_card/mycard_tokyu.html"
              target="_blank"
              rel="noopener noreferrer"
              css={style.txtlink}
            >
              マイナンバーカードの特急発行申請について（※外部リンクが開きます。）
            </a>
          </p>
          <div css={style.wrapTbl}>
            <p css={style.tableTtl}>問い合わせ先（予約申し込み先）</p>
            <table css={[cmnStyle.table, style.table]}>
              <thead>
                <tr>
                  <th css={style.header01}>区</th>
                  <th css={style.header02}>担当所属</th>
                  <th css={style.header03}>直通電話番号</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>北区役所</td>
                  <td>区民生活課 区民窓口係</td>
                  <td>電話：025-387-1265</td>
                </tr>
                <tr>
                  <td>東区役所</td>
                  <td>区民生活課 区民窓口係</td>
                  <td>電話：025-250-2235</td>
                </tr>
                <tr>
                  <td>中央区役所</td>
                  <td>窓口サービス課 証明チーム</td>
                  <td>電話：025-223-7106</td>
                </tr>
                <tr>
                  <td>江南区役所</td>
                  <td>区民生活課 区民窓口係</td>
                  <td>電話：025-382-4203</td>
                </tr>
                <tr>
                  <td>秋葉区役所</td>
                  <td>区民生活課 区民窓口係</td>
                  <td>電話：0250-25-5674</td>
                </tr>
                <tr>
                  <td>南区役所</td>
                  <td>区民生活課 区民窓口担当</td>
                  <td>電話：025-372-6105</td>
                </tr>
                <tr>
                  <td>西区役所</td>
                  <td>区民生活課 区民窓口係</td>
                  <td>電話：025-264-7211</td>
                </tr>
                <tr>
                  <td>西蒲区役所</td>
                  <td>区民生活課 区民窓口係</td>
                  <td>電話：0256-72-8317</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div css={style.exampleBlock}>
          <p css={cmnStyle.blockTtl}>
            マイナンバーカードの作り方・利用方法などについて
          </p>
          <a
            href="https://www.kojinbango-card.go.jp/"
            target="_blank"
            rel="noopener noreferrer"
            css={cmnStyle.btn}
          >
            <span>詳しくはこちら</span>
          </a>
          <p css={cmnStyle.btnDescription}>
            ※外部リンクが開きます。
            <br />
            マイナンバーカード総合サイトHP
          </p>
        </div>
      </Content>
    </section>
  )
}

export default Apply
