import React from 'react'
import Head from 'next/head'

interface OwnProps {
  siteTitle?: string
  pageDescription?: string
  pagePath?: string
  pageImg?: string
  pageType?: string
  pageImgWidth?: number
  pageImgHeight?: number
}

type Props = OwnProps

const defaultTitle = '新潟市 マイナンバーカード 普及促進キャンペーン'
const defaultDescription =
  '新潟市民の皆様、マイナンバーカードをつくりませんか？手続きラクラクな出張申請会場をご利用ください！Webサイトで会場・日程をチェック！'

const origin =
  typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : ''

const HeadInfo: React.VFC<Props> = ({
  siteTitle,
  pageDescription,
  pagePath,
  pageImg,
  pageType,
  pageImgWidth,
  pageImgHeight,
}) => {
  const title = siteTitle ? siteTitle : defaultTitle
  const description = pageDescription ? pageDescription : defaultDescription
  const path = pagePath ? pagePath : ''
  const type = pageType ? pageType : 'website'
  const imgUrl = pageImg ? pageImg : `${origin}/home/img/main.png`
  const imgWidth = pageImgWidth ? pageImgWidth : 1200
  const imgHeight = pageImgHeight ? pageImgHeight : 640

  return (
    <Head>
      <meta charSet="UTF-8" />
      <title>{siteTitle}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={`${origin}${path}`} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={imgUrl} />
      <meta property="og:image:width" content={String(imgWidth)} />
      <meta property="og:image:height" content={String(imgHeight)} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="" />
      <meta
        name="format-detection"
        content="telephone=no,address=no,email=no"
      />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
  )
}

export default HeadInfo
