import React from 'react'
import { CSSProp } from 'styled-components'
import getConfig from 'next/config'

import { style } from './Certificate.style'
import { cmnStyle } from './homeCommon.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Measures = () => {
  return (
    <section id="certificate_section">
      <Content>
        <SectionTitle sectionTitle="スマホ用電子証明書搭載サービス" />
        <div css={style.infoBlock}>
          <p css={cmnStyle.blockTtl}>サービス概要</p>
          <p css={style.exampleTxt}>
            マイナンバーカードなしで、スマートフォンだけで、様々なサービスの利用や申込ができるようになります。
          </p>
          <a
            href={`${basePath}/home/pdf/DigitalCertificate_for_smartphones.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            css={cmnStyle.btn}
          >
            <span>詳しくはこちら</span>
          </a>
          <p css={cmnStyle.btnDescription}>※PDFが開きます。<br />【リーフレット】マイナンバーカードのスマホ用電子証明書について（PDF：2,116KB）</p>
        </div>
        <div css={style.infoBlock}>
          <p css={cmnStyle.blockTtl}>スマートフォン機種変更時の手続きについて</p>
          <p css={style.exampleTxt}>
          機種変更後もスマホ用電子証明書を利用する場合は、新しいスマホから、スマホ用電子証明書の利用手続を行ってください。
          </p>
          <a
            href={`${basePath}/home/pdf/DigitalCertificate_kisyuhennkou.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            css={cmnStyle.btn}
          >
            <span>詳しくはこちら</span>
          </a>
          <p css={cmnStyle.btnDescription}>※PDFが開きます。<br />スマホ用電子証明書搭載サービス機種変更時リーフレット（PDF：1,737KB）</p>
        </div>
        <div css={style.infoBlock}>
          <p css={cmnStyle.blockTtl}>お問い合わせ</p>
          <p css={style.exampleTxt}>
            スマホ用電子証明書について不明点がある場合、マイナンバー総合フリーダイヤルへお問い合わせください。
          </p>
          <p css={style.infoTxt}>マイナンバー総合フリーダイヤル</p>
          <div css={style.telBlock}>
            <img src={`${basePath}/common/tel.png`} />
            <p>0120-95-0178</p>
          </div>
        </div>
      </Content>
    </section>
  )
}

export default Measures
