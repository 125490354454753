import { css } from 'styled-components'
import { minMq, maxMq } from './breakPoints'
import variables from './variables'
// import { defineIcoMoon } from '../../shared/styles/icomoon';
// import '../fonts';

const common = css`
  main {
    flex: 1;
  }

  section {
    + section {
      margin-top: 120px;
    }

    header {
      margin-bottom: 25px;

      ${minMq('MD')} {
        margin-bottom: 40px;
      }
    }
  }

  section + section {
    margin-top: 80px;

    ${minMq('MD')} {
      margin-top: 120px;
    }
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 700;
    padding: 8px 20px 7px;
    background-color: ${variables.LIGHT_BLUE};

    ${minMq('MD')} {
      font-size: 2rem;
      padding: 11px 26px 10px;
    }
  }

  a {
    cursor: pointer;
    transition: opacity .2s ease;
    
    &.is-hover {
      opacity: .75;
    }
    
    img,
    span {
      display: block;
      max-width: 100%;
      pointer-events: none;
      transition: all .2s;
    }
  }
  
  button {
    cursor: pointer;
  }

  [data-mq='sp'] {
    ${minMq('MD')} {
      display: none !important;
    }
  }

  [data-mq='pc'] {
    ${maxMq('MD')} {
      display: none !important;
    }
  }
`

export default common
