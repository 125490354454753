import React from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { createGlobalStyle, CSSProp } from 'styled-components'
import HeadInfo from '../HeadInfo'
import SiteHeader from '../SiteHeader/SiteHeader'
import SiteFooter from '../SiteFooter/SiteFooter'
import ScrollTop from '../ScrollTop/ScrollTop'
import Body from '../Body'
import normalize from '../../shared/styles/normalize'
import common from '../../shared/styles/common'
import { style } from './Page.style'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const GlobalStyle = createGlobalStyle`
${normalize} 
${common}
`

interface OwnProps {
  title: string
  children: React.ReactNode
}

type Props = OwnProps

const Page: NextPage<Props> = (props) => {
  const { asPath } = useRouter()

  return (
    <div css={style.page}>
      <GlobalStyle />
      <HeadInfo siteTitle={props.title} pagePath={asPath} pageImgHeight={650} />
      <SiteHeader />
      <Body>{props.children}</Body>
      <SiteFooter />
      <ScrollTop />
    </div>
  )
}

export default Page
