import { css } from 'styled-components'
import { maxMq, minMq } from '../shared/styles/breakPoints'
import variables from '../shared/styles/variables'

const card = css`
  border-bottom: 1px solid ${variables.BASE_BDC};
  display: flex;
  padding-bottom: 30px;

  + article {
    padding-top: 20px;
  }
`

const area = css`
  border-left: 3px solid;
  flex-basis: 80px;
  flex-shrink: 1;
  font-weight: 700;
  padding: 5px 13px;
  position: relative;

  &::after {
    content: "";
    height: 100%;
    left: 0;
    opacity: .06;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`

const funcAreaC = (areaC: string) => css`
  border-color: ${areaC};
  color: ${areaC};

  &::after {
    background-color: ${areaC};
  }
`

const wrapMainTxt = css`
  flex: 1;
  font-weight: 700;
  padding-left: 20px;
`

const titleBlock = css`
  > * {
    display: inline-block;
  }
`

const title = css`
  font-size: 1.8rem;

  ${maxMq('MD')} {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
`

const capacity = css`
  background-color: ${variables.LIGHT_PINK};
  border-radius: 9999px;
  padding: 6px 11px;

  ${minMq('MD')} {
    margin-left: 20px;
  }
`

const pdfTooltip = css`
  margin-left: 20px;
  vertical-align: sub;
`

const dList = css`
  margin-top: 17px;
`

const dLItem = css`
  align-items: baseline;
  display: flex;
  line-height: 24px;

  > dt {
    background-color: ${variables.LIGHT_GRAY};
    flex-basis: 104px;
    flex-shrink: 1;
    padding: 3px 19px;
    text-align: center;
  }

  > dd {
    display: inline-block;
    flex: 1;
    padding-left: 10px;
  }

  + * {
    margin-top: 10px;
  }
`

const eventDate = css`
  ${maxMq('MD')} {
    font-size: 1.6rem;
  }
`

const address = css`
  ${maxMq('MD')} {
    display: block;
    font-weight: 500;
  }
`

const mapBtn = css`
  border: 1px solid ${variables.BASE_TXC};
  border-radius: 9999px;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 18px;
  margin-left: 20px;
  padding: 6px 15px;
  
  ${maxMq('MD')} {
    font-weight: 700;
    margin-left: 0;
    margin-top: 5px;
  }
`

const memo = css`
  color: ${variables.GRAY};
  font-size: 1.4rem;
  line-height: 20px;
  margin-top: 16px;
`

export const style = {
  card,
  area,
  funcAreaC,
  wrapMainTxt,
  titleBlock,
  title,
  capacity,
  pdfTooltip,
  dList,
  dLItem,
  eventDate,
  address,
  mapBtn,
  memo
}