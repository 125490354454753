import React from 'react'
import { CSSProp } from 'styled-components'

import Page from '../components/Page/Page'
import PageHeader from '../components/PageHeader/PageHeader'
import Guidance from '../components/Home/Guidance'
import News from '../components/Home/News'
import WhatIs from '../components/Home/WhatIs'
import Merit from '../components/Home/Merit'
import Measures from '../components/Home/Measures'
import Necessary from '../components/Home/Necessary'
import Apply from '../components/Home/Apply'
import Contact from '../components/Home/Contact'
import Certificate from '../components/Home/Certificate'

const pageTitle = '新潟市 マイナンバーカード 普及促進キャンペーン'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Home = () => {
  return (
    <Page title={pageTitle}>
      <PageHeader pageTitle="Home Page" />
      <Guidance />
      <Necessary />
      <News />
      <WhatIs />
      <Merit />
      <Certificate />
      <Measures />
      <Apply />
      <Contact />
    </Page>
  )
}
export default Home
