import React from 'react'
import Link from 'next/link'
import { CSSProp } from 'styled-components'

import { style } from './SiteHeader.style'
import Navigation from '../Navigation/Navigation'

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const SiteHeader: React.VFC = () => {
  return (
    <header css={style.siteHeader} id="site_header">
      <div css={style.wrapContent}>
        <div css={style.siteTitle}>
          <Link href="/">
            <a>
              <h1><span>新潟市 マイナンバーカード&nbsp;</span><span>普及促進キャンペーン</span></h1>
            </a>
          </Link>
        </div>
        <Navigation />
      </div>
    </header>
  )
}

export default SiteHeader
