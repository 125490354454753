import React from 'react'
import { css } from 'styled-components'
import { maxMq, minMq } from '../shared/styles/breakPoints'
import variables from '../shared/styles/variables'

const sectionHeader = css`
  margin-bottom:  40px;

  ${minMq('MD')} {
    margin-bottom:  60px;
  }
`

const sectionTitle = css`
  background-color: ${variables.THEME_C};
  border-radius: 9999px;
  color: ${variables.WHITE};
  font-size: 3rem;
  font-weight: 900;
  margin: 0 auto;
  padding: 17px 59px 18px;

  ${maxMq('MD')} {
    font-size: 2rem;
    padding-left: 20px;
    padding-right: 20px;
  }

  > span {
    display: block;
    line-height: 30px;
    text-align: center;

    ${minMq('MD')} {
      line-height: 45px;
    }
  }
`

interface OwnProps {
  sectionTitle: string
}

type Props = OwnProps

const SectionTitle: React.VFC<Props> = (props) => {
  return (
    <header css={sectionHeader}>
      <h2 css={sectionTitle}>
        <span>{props.sectionTitle}</span>
      </h2>
    </header>
  )
}

export default SectionTitle
