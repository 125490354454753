import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const topImg = css`
  display: block;
  margin: 0 auto 43px;
  max-width: 740px;
  width: 100%;

  ${maxMq('MD')} {
    margin-bottom: 30px;
  }
`

const txtBlock = css`
  background-color: ${variables.LIGHT_YELLOW};
  margin-top: 20px;
  padding: 30px 45px 30px;

  ${minMq('MD')} {
    margin-top: 30px;
    padding: 46px 70px 43px;
  }

  p {
    font-size: 1.7rem;
    font-weight: 700;
    line-height: 25px;
    
    ${minMq('MD')} {
      font-size: 2rem;
      line-height: 30px;
    }
  }

  em {
    color: ${variables.SUB_C};
  }

  small {
    display: block;
    line-height: 24px;
    margin-top: 10px;
  }
`

const infoBlock = css`
  font-weight: 700;
  margin-top: 30px;

  ${minMq('MD')} {
    margin-top: 60px;
  }
`

const infoTxt = css`
  font-size: 1.6rem;
  margin-bottom: 15px;

  ${minMq('MD')} {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
`

const telBlock = css`
  margin-bottom: 30px;
  
  ${minMq('MD')} {
    margin-bottom: 64px;
  }

  > * {
    display: inline-block;
  }

  img {
    width: 30px;
    
    ${minMq('MD')} {
      width: 50px;
    }
  }

  p {
    font-size: 2.2rem;
    margin-left: 7px;
    
    ${minMq('MD')} {
      font-size: 3rem;
      margin-left: 15px;
    }
  }

  small {
    font-size: 1.6rem;
    margin-left: 10px;
    
    ${minMq('MD')} {
      font-size: 1.8rem;
      margin-left: 20px;
    }
  }
`

export const style = {
  topImg,
  txtBlock,
  infoBlock,
  infoTxt,
  telBlock
}