export default {
  WHITE: '#fff',
  BLACK: '#000',
  RED: '#FF0000',
  LIGHT_RED: '#DB3434',
  GREEN: '#488b4b',
  AQUA: '#009FE8',
  BLUE: '#0f67c3',
  LIGHT_BLUE: '#B6DFF3',
  ROSE: '#E96087',
  PINK: '#e3556a',
  LIGHT_PINK: '#FFEBED',
  YELLOW: '#FFF100',
  LIGHT_YELLOW: '#FFFAE6',
  GRAY: '#8B8B8B',
  DARK_GRAY: '#606060',
  LIGHT_GRAY: '#F2F2F2',

  BASE_BGC: '#fef6e5',
  SUB_BGC: '#f2f2f2',
  BASE_BDC: '#E4E4E4',
  SUB_BDC: '#c4c4c4',
  BASE_TXC: '#0C0C0C',
  SUB_TXC: '#231815',

  // テーマカラー
  THEME_C: '#00B8EE',
  SUB_C: '#FF7D23',

  // エラー表示用の強調色
  ERR_C: '#f20606',
  //$err_bgc: tint($err_c, 95%);

  // リンクの色
  LINK_TXC: '#1a73e8',
  LINK_HVC: '#18e2e2',

  // ボタンの色
  BTN_BGC: '#FF7D23',
  //$btn_hvc: lighten($theme_c, 15%);
  BTN_TXC: '#fff',

  // 地区色
  AREA_C_CHUO: '#0037CF',
  AREA_C_HIGASHI: '#0086CF',
  AREA_C_NISHI: '#EF5006',
  AREA_C_MINAMI: '#00B9EF',
  AREA_C_KITA: '#007247',
  AREA_C_AKIHA: '#009F3B',
  AREA_C_NISHIKAN: '#F5A100',
  AREA_C_KONAN: '#B4B801',
  AREA_C_KAKUKU: '#df0125',

  // size
  UNIT: '5px',
  BLOCK_SPACE: `${5 * 6}px`,
  BLOCK_PAD: `${5 * 4}px`,

  CONTENT_W: '1020px',
  HEADER_H_PC: '80px',
  HEADER_H_SP: '60px',
  GLOBAL_NAV_W: '50px',
  LOGO_H_SP: '30px',
  BTN_CONTACT_H_SP: '40px'
}
