import React from 'react'
import { CSSProp } from 'styled-components'
import getConfig from 'next/config'
// import Link from 'next/link'
// import { Link as Scroll } from 'react-scroll'

import { style } from './Guidance.style'
import { cmnStyle } from './homeCommon.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Guidance = () => {
  return (
    <section id="guidance_section">
      <Content>
        <SectionTitle sectionTitle="出張申請サービスのご案内" />
        <img
          src={`${basePath}/home/img/guidance01.png`}
          alt="新潟市職員が特設会場に訪問し、申請をお手伝いします。 便利な出張申請サービスをぜひご利用ください！"
          data-mq="pc"
          css={style.topImg}
        />
        <img
          src={`${basePath}/home/img/guidance01Sp.png`}
          alt="新潟市職員が特設会場に訪問し、申請をお手伝いします。 便利な出張申請サービスをぜひご利用ください！"
          data-mq="sp"
          css={style.topImg}
        />
        <div css={style.targetBlock}>
          <p css={cmnStyle.blockTtl}>受付対象者</p>
          <ul css={style.list}>
            <li>
              マイナンバーカードを「新規作成」する新潟市民（住民票がある方に限る）
            </li>
            <li>申請者本人（15歳未満の方は、本人及び親権者）が来場できる方</li>
          </ul>
        </div>
      </Content>
    </section>
  )
}
export default Guidance
