import { css } from 'styled-components'
import { minMq, maxMq } from '../../shared/styles/breakPoints'
// import variables from '../../shared/styles/variables'

const topTxt = css`
  font-size: 1.8rem;
  font-weight: 700;

  ${minMq('MD')} {
    font-size: 2rem;
    line-height: 29px;
  }
`

const topAttention = css`
  font-size: 1.6rem;
  margin-top: 10px;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

const wrapTbl = css`
  margin-top: 30px;

  ${maxMq('MD')} {
    margin-top: 20px;
    overflow-x: scroll;
  }
`

const tableTtl = css`
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 15px;

  ${minMq('MD')} {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
`

const table = css`
  padding-bottom: 10px;

  ${maxMq('MD')} {
    min-width: 1000px;
  }

  td {
    padding: 17px 30px;
  }

  thead {
    tr {
      height: 40px;
    }
  }

  tbody {
    tr {
      height: 54px;
    }
  }
`

const header01 = css`
  width: 150px;
`

const header02 = css`
  width: 424px;
`

const header03 = css`
  width: 424px;
`

const exampleBlock = css`
  margin-top: 30px;

  ${minMq('MD')} {
    margin-top: 50px;
  }
`

const exampleTxt = css`
  font-size: 1.6rem;
  line-height: 27px;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

const includesAttention = css`
  > span {
    display: inline-block;

    &:first-child {
      margin-right: 2em;
    }
  }
`

const exampleNote = css`
  margin-top: 16px;

  > span {
    display: block;
  }
`

const txtlink = css`
  color: #ff7d23;
  margin-left: 1em;
  text-decoration: underline;
`

const btn = css`
  margin-top: 20px;

  ${minMq('MD')} {
    margin-top: 40px;
  }
`

export const style = {
  topTxt,
  topAttention,
  wrapTbl,
  tableTtl,
  table,
  header01,
  header02,
  header03,
  exampleBlock,
  exampleTxt,
  includesAttention,
  exampleNote,
  btn,
  txtlink,
}
