import React from 'react'
import { CSSProp } from 'styled-components'
import getConfig from 'next/config'

import { style } from './Necessary.style'
import Content from '../Content/Content'
import SectionTitle from '../SectioinTitle'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}

const Necessary = () => {
  return (
    <section id="necessary_section">
      <Content>
        <SectionTitle sectionTitle="出張申請会場に持参するもの" />
        <ul css={style.list}>
          <li>
            <div css={style.txtBlock}>
              <p>①マイナンバーカード交付申請書</p>
              <small>※①は会場に手書き用書式をご用意しますが、自宅に郵送された「申請書ID」（23桁の数字）入りの書式をお持ちいただくとスムーズです。</small>
            </div>
            <div css={style.imgBlock}>
              <img src={`${basePath}/home/img/necessary01.png`} />
            </div>
          </li>
          <li>
            <div css={style.txtBlock}>
              <p>②本人確認書類２点以上</p>
              <small>運転免許証、健康保険証 など<br />※できるだけ顔写真入りのものをお持ちください</small>
            </div>
            <div css={style.imgBlock}>
              <img src={`${basePath}/home/img/necessary02.png`} />
            </div>
          </li>
          <li>
            <div css={style.txtBlock}>
              <p>③通知カード（または個人番号通知書）、<br data-mq='pc' />【お持ちの方】住民基本台帳カード</p>
            </div>
            <div css={style.imgBlock}>
              <img src={`${basePath}/home/img/necessary03.png`} />
            </div>
          </li>
        </ul>
      </Content>
    </section>
  )
}

export default Necessary
