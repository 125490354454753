import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const list = css`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${minMq('MD')} {
    gap: 30px;
  }

  > li {
    background-color: ${variables.LIGHT_YELLOW};
    display: flex;
    gap: 47px;
    padding: 20px 15px;
    width: 100%;

    ${maxMq('MD')} {
      flex-direction: column;
      gap: 15px;
    }
  }
`

const txtBlock = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 5px;
  justify-content: center;

  ${minMq('MD')} {
    gap: 10px;
  }

  p {
    font-size: 1.8rem;
    font-weight: 700;

    ${minMq('MD')} {
      font-size: 2rem;
      line-height: 29px;
    }
  }

  small {
    line-height: 24px;
  }
`

const imgBlock = css`
  width: 300px;

  ${maxMq('MD')} {
    display: block;
    margin: 0 auto;
    max-width: 300px;
    width: 100%;
  }
`

export const style = {
  list,
  txtBlock,
  imgBlock
}