import { css } from 'styled-components'
import { minMq, maxMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const wrapMenu = css`
  align-items: center;
  display: flex;
  flex-shrink: 1;
  gap: 12px;
`

const navSide = css`
  font-size: 1.4rem;
  font-weight: 700;

  ${minMq('MD')} {
    font-size: 2rem;
  }
`

const smallContent = css`
  pointer-events: all;
  right: 0 !important;
`

const nav = css`
  background-color: ${variables.THEME_C};
  padding: 20px 50px 83px;
  pointer-events: none;
  position: fixed;
  transition: all .3s ease-in;
  width: 100%;

  ${minMq('MD')} {
    max-width: 450px;
    min-height: 687px;
    top: ${variables.HEADER_H_PC};
    right: -450px;
  }

  ${maxMq('MD')} {
    height: calc(100lvh - 60px);
    overflow-x: hidden;
    overflow-y: scroll;
    right: -103%;
    top: ${variables.HEADER_H_SP};
  }
`

const openNavList = css`
  pointer-events: all;
`

const navList = css`
  box-sizing: border-box;
  font-weight: 500;
  height: auto;
`

const navItem = css`
  border-bottom: 2px solid ${variables.WHITE};
  color: ${variables.BASE_TXC};
  display: block;

  a {
    align-items: center;
    display: flex;
    padding-bottom: 10px;
    padding-top: 20px;

    ${minMq('MD')} {
      padding-bottom: 13px;
      padding-top: 23px;
    }
  }
`

const navLink = css`
  color: ${variables.WHITE};
  font-size: 1.4rem;
  font-weight: 700;

  ${minMq('MD')} {
    font-size: 1.6rem;
  }
`

const menu = css`
  cursor: pointer;
  height: 36px;
  position: relative;
  width: 50px;
  z-index: 11;
`

const bar = css`
  background-color: ${variables.BASE_TXC};
  border-radius: 1px;
  display: block;
  height: 4px;
  max-height: 2px;
  min-height: 4px;
  position: absolute;
  right: 0;
  transition: 0.6s ease;
  width: 100%;
  
  &:nth-child(1){
    top:0;
  }
  &:nth-child(2){
    top: 16px;
  }
  
  &:nth-child(3){
    bottom: 0;
  }
`

const open = css`
  span{
    &:nth-child(1){
      right: -3px;
      top: 16px;
      transform: rotate(-45deg);
      width: 54px;
    }
    
    &:nth-child(2){
      opacity: 0;
    }
    
    &:nth-child(3){
      bottom: 16px;
      right: -3px;
      transform: rotate(45deg);
      width: 54px;
    }
  }
`

export const style = {
  wrapMenu,
  navSide,
  smallContent,
  nav,
  openNavList,
  navList,
  navItem,
  navLink,
  menu,
  bar,
  open
}